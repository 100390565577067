import React, { useState, useEffect } from 'react';
import * as JsSearch from 'js-search';
import usePostPreviews from '~/hooks/usePostPreviews';

export default function useSearch() {
  const [search, setSearch] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const postsPreviews = usePostPreviews({ resourceType: '' });

  const queryResults = searchQuery === '' ? [] : searchResults;

  /**
   * rebuilds the overall index based on the options
   */
  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search('id');

    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex();

    const posts = postsPreviews.map((itm) => ({
      ...itm,
      categories: itm.categories.nodes.map(({ name }) => name),
    }));

    dataToSearch.addIndex('title');
    dataToSearch.addIndex('excerpt');
    dataToSearch.addIndex('categories');

    dataToSearch.addDocuments(posts);

    setSearch(dataToSearch);
  };

  useEffect(() => {
    rebuildIndex();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search?.search) {
      const queryResult = search.search(searchQuery);
      setSearchResults(queryResult);
    }
  }, [searchQuery]);

  return {
    searchQuery,
    setSearchQuery,
    queryResults
  };
}