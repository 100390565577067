import React from 'react';
import { useDebounce } from 'react-use';
import Layout from '~/components/Layout';
import StoryPreview from '~/components/molecules/StoryPreview';
import useSearch from '~/hooks/useSearch';
import globalState from '~/store/index';

export default function SearchPage() {
  const [ searchVal ] = globalState('search');
  const { setSearchQuery, queryResults } = useSearch();

  useDebounce(() => {
    setSearchQuery(searchVal);
  }, 300, [searchVal]);


  return (
    <Layout showResourcesNav headerBgWhite>
      <div className="pt-48 container">
        <h1 className="text-h1">Results for: {searchVal}</h1>
      </div>

      <div className="py-20 md:py-32 md:pt-12 container">
        <div className="grid grid-cols-3 gap-8 w-full">
          {queryResults.map((item, i) => (
            <StoryPreview key={item?.id+i} {...item} className="w-full" />
          ))}
        </div>
      </div>
    </Layout>
  );
}