import { graphql, useStaticQuery } from 'gatsby';

const usePostPreviews = ({ resourceType='' }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: { categories: { nodes: { elemMatch: { name:{ nin: [ "Uncategorized", "Uncategorised"] } }}} },
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          ...PostPreview
        }
      }
    }
  `);

  const allPosts = data?.allWpPost?.nodes;

  // Filter by resource type (residential || partner)
  if (resourceType?.length > 0) {
    return allPosts.filter(p => (p.resourceTypes.nodes.filter(({slug}) => slug === resourceType).length > 0))
  }

  return allPosts;
};

export default usePostPreviews;
